<template>
  <div class="CEESAR-View">
      <div class="CEESAR-ViewHeader">
        <div class="CEESAR-ViewTitle">{{title}}
          <div class="CEESAR-ViewHeaderMenu">
            <slot name="Menu"/>
          </div>
        </div>
      </div>

      <div class="CEESAR-ViewContent">
        <slot />
      </div>
      
      <div class="CEESAR-ViewFooter" />
  </div>
</template>

<script>
export default {
    name: "CEESAR-View",
    props:{
        title:{type: String, required: true}
    }
}
</script>

<style>

</style>